import React, { useEffect, useState, useCallback } from "react"
import PageSmall from "./../components/page-small"

import { getTransactions } from "./../utils/services"

const IndexPage = () => {
  const [transactions, setTransactions] = useState([])

  const loadTransactions = useCallback(async () => {
    const res = await getTransactions()
    setTransactions(res.data.results)
  }, [])

  useEffect(() => {
    loadTransactions()
  }, [loadTransactions])

  return (
    <PageSmall title="Transactions" pageTitle="Transactions">
      <div className="mt-8 md:w-4/6 mx-auto p-8 sm:p-0">
        {transactions.length > 0 &&
          transactions.map(({ date, type, teams }, index) => (
            <div className="mb-10" key={`transaction-${index}`}>
              <p className="text-md">{date}</p>
              <p className="text-xl mb-2 text-gray-500 font-oswald">{type}</p>
              <p
                className="text-base"
                dangerouslySetInnerHTML={{
                  __html: teams.replace(/(?:\r\n|\r|\n)/g, "<br>"),
                }}
              />
            </div>
          ))}

        {transactions.length === 0 && (
          <div className="pb-32 pt-16">
            <p className="text-center text-2xl">Loading...</p>
          </div>
        )}
      </div>
    </PageSmall>
  )
}

export default IndexPage
